import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { AdminConstant } from '../../../helper/constant';
import { AuthService } from '../../../helper/services/auth.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  admin_constant: any = AdminConstant;
  // user: any = {
  //   // name: 'Super Admin',
  //   picture: 'assets/images/nick.png'
  // };
  login_user_data: any = {
    name: '',
    image: "",
  };


  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;


  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    // {
    //   value: 'cosmic',
    //   name: 'Cosmic',
    // },
    // {
    //   value: 'corporate',
    //   name: 'Corporate',
    // },
  ];

  currentTheme = AdminConstant.currentTheme;

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() {
    // avi
    this.themeService.changeTheme(this.currentTheme);
    this.authService.login_user_data.subscribe(res => {
      this.login_user_data.name = res.first_name + " " + res.last_name;

      this.checkUrl(`${environment.img_url}${res.image}`);
      // this.login_user_data.image = `${environment.img_url}${res.image}`;
    })

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    console.log(themeName, "Theme Name in header ====");

    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    console.log("Navigate Home");
    // this.menuService.navigateHome();
    this.router.navigate['/pages/resume/dashboard'];
    return false;
  }

  userMenuClick(event: any) {
    console.log(event, "User Menu Click");

  }


  validateUrl(url: string) {
    // Send a HEAD request to validate the URL
    return this.http.head(url, { observe: 'response' }).pipe(
      map(response => response.status === 200),
      catchError(() => of(false))
    );
  }

  checkUrl(url) {
    this.validateUrl(url).subscribe(isValid => {
      if (isValid) {
        console.log('URL is valid!');
        this.login_user_data.image = url;
      } else {
        console.log('URL is not valid.');
        this.login_user_data.image = "../../../../assets/dummy-user.png";
      }
    });
  }
}
